
import {Options, Vue} from 'vue-class-component';
import Service from '@/services/server/web';
import {ILeagueResult, ILeagueList} from '@/model';
import screenTable from './ScreenTable/index.vue';

@Options({
  components: {
    screenTable
  }
})
export default class Login extends Vue {
  list: Array<ILeagueList> = [];
  total = 0;
  page = 1;

  created() {
    this.getList();
  }

  async getList() {
    const res = await Service.Common.getLeagueList<ILeagueResult>({
      page: this.page,
      size: 10
    });
    if (!(res instanceof Error)) {
      this.list = res.data;
      this.total = res.total;
    }
  }

  pageChange(e: number) {
    this.page = e;
    this.getList();
  }
}
