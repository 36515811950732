<template>
  <div class="main-conent-screen">
    <div v-if="$slots.header" class="screen-header">
      <slot name="header"></slot>
    </div>
    <div v-else class="screen-padding" />
    <div class="screen-conent">
      <el-table :data="data" style="width: 100%">
        <el-table-column :label="$t('league__img')" width="100">
          <template #default="scope">
            <img :src="`http://${scope.row.domain}${scope.row.icon}`" alt="" class="listImg" />
          </template>
        </el-table-column>
        <el-table-column prop="area_name" :label="$t('league__area')"> </el-table-column>
        <el-table-column prop="country_name" :label="$t('league__country')"> </el-table-column>
        <el-table-column prop="name" :label="$t('league__match')"> </el-table-column>
      </el-table>
    </div>
    <div class="screen-footer page">
      <el-pagination layout="prev, pager, next" :total="total" @current-change="pageChange" />
    </div>
  </div>
</template>
<script lang="ts">
import {Options, Vue} from 'vue-class-component';

@Options({
  props: {
    data: {
      type: Array
    },
    pageChange: Function,
    total: {
      type: Number
    }
  }
})
export default class Login extends Vue {}
</script>
<style lang="scss" scoped>
@import '../../../../common/styles/element.scss';
.main-conent-screen {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: #fff;
  .screen-header {
    padding: 20px;
    min-height: 33px;
  }
  .screen-footer {
    padding: 20px;
    min-height: 32px;
    text-align: right;
  }
  .screen-conent {
    flex: 1;
    padding: 0 20px;
    overflow: hidden;
  }
  .screen-padding {
    padding-top: 20px;
  }
}
.page {
  width: unset;
}
.listImg {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #eee;
  display: inline-block;
  object-fit: cover;
}
</style>
